import { LocalStorageKeys } from 'config/localStorageKeys'
import { useEffect } from 'react'
import { setLocalStorage } from 'utils/browserStorage'

export const useSetDevice = (nativeDevice?: string, nativeDeviceVersion?: string) => {
  useEffect(() => {
    /* istanbul ignore if */
    if (typeof window === 'undefined') {
      return
    }

    if (nativeDeviceVersion) {
      setLocalStorage(LocalStorageKeys.DEVICE_VERSION, nativeDeviceVersion)
    }

    // empty dependencies array so this effect can only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    /* istanbul ignore if */
    if (typeof window === 'undefined') {
      return
    }

    if (nativeDevice) {
      setLocalStorage(LocalStorageKeys.DEVICE, nativeDevice)
      return
    }

    if (window.innerWidth < 768) {
      setLocalStorage(LocalStorageKeys.DEVICE, 'mobile-web')
      return
    }

    setLocalStorage(LocalStorageKeys.DEVICE, 'desktop')

    // empty dependencies array so this effect can only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
