import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import Screen from 'screens/Launch'

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return {
    props: {
      query: context?.query,
    },
  }
}

const Launch = ({ query }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <Screen query={query} />
  )
}

export default Launch
