import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { LocalStorageKeys } from 'config/localStorageKeys'
import { useLocalStorage } from 'hooks/useLocalStorage'
import useProfile from 'hooks/useProfile'
import { compare } from 'compare-versions'
import { useSetDevice } from 'hooks/useSetDevice'
import { api } from 'config/apiClient'
import { useRecoilState } from 'recoil'
import { cartState } from 'config/states'

export type LaunchScreenProps = {
  query: {
    device?: 'ios' | 'android'
    /** @example '1.0.0' */
    version?: string
    lat?: string
    lon?: string
    /** uuid */
    playerId?: string
  }
}

const Launch = ({ query: { device: nativeDevice, version } }: LaunchScreenProps) => {
  // the native app should tell us via URL query which device it's running on,
  // and the native app version.
  useSetDevice(nativeDevice, version)

  const [locationServices] = useLocalStorage(LocalStorageKeys.LOCATION_SERVICES, 'false')

  const [profile] = useProfile()
  const router = useRouter()
  const [, setCart] = useRecoilState(cartState)
  /* istanbul ignore next */
  const { userAgent } = typeof window !== 'undefined' ? navigator : { userAgent: undefined }

  const populateCart = async () => {
    const { data } = await api('getCart', undefined)

    if (data.cart_data) {
      setCart(data.cart_data)
    }
  }

  useEffect(() => {
    // check the native app version; if out-of-date,
    // redirect to an upgrade notice.
    if (
      (!!version && compare(version, '3.1.5', '<')) ||
      userAgent === 'joe coffee native host' // is legacy app?
    ) {
      router.push({ pathname: '/lp/upgrade' })
      return
    }

    if (locationServices !== 'true') {
      router.push({ pathname: '/explore/location-permissions' })
      return
    }

    if (profile) {
      /* istanbul ignore else */
      if (nativeDevice) {
        populateCart()
      }

      router.push({ pathname: '/my-joe' })
      return
    }

    if (nativeDevice) { // if this is passed in, it's a native app
      router.push({
        pathname: '/sign-in/phone',
        query: { isSoftGate: true }
      })

      return
    }

    router.push({ pathname: '/explore/stores' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationServices, profile, userAgent])

  return null
}

export default Launch
